
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        // Fields

        // Data function
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                oculusIconPathAndName:
                    require("@/assets/images/oculus.png"),

            };
        }
    }
